















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'

@Component
export default class About extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer
}
